// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';
import React from 'react';

import { token } from '@atlaskit/tokens';
import Spinner from '@atlaskit/spinner/spinner';

import { LoadableLazy } from '@confluence/loadable';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 32px;
	width: 32px;
	margin-right: ${token('space.150', '12px')};
`;

const LoadingSpinner = () => (
	<SpinnerContainer>
		<Spinner />
	</SpinnerContainer>
);

export const LazyNavigation = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-Navigation" */ './Navigation'))
			.NavigationPresenterMode,
	loading: () => <LoadingSpinner />,
});

export const LazySettingsMenu = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-SettingsMenu" */ './SettingsMenu')).SettingsMenu,
	loading: () => <LoadingSpinner />,
});
