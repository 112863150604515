// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';
import { h800 } from '@atlaskit/theme/typography';
import { N0, N20 } from '@atlaskit/theme/colors';

import {
	EMOJI_SIZE_LARGE,
	EMOJI_SIZE_MEDIUM,
} from '@confluence/emoji-title/entry-points/constants';

import cursorImg from './img/customCursor.svg';

type PresenterModePortalContainerProps = {
	isCustomCursorActive: boolean;
};

type TitleContainerProps = {
	isCustomSitesPageTitleFFOn?: boolean;
	isTitleCenterAligned?: boolean;
	hasCoverPicture?: boolean;
	hasEmoji?: boolean;
	width?: number;
};

type HeaderImageContainerProps = {
	isCustomSitesPageTitleFFOn?: boolean;
};

type EmojiWrapperProps = {
	isCustomSitesPageTitleFFOn?: boolean;
	hasCoverPicture?: boolean;
};

type TitleTextWrapperProps = {
	shouldAddLeftPaddingCustomSites?: boolean;
	shouldAddLeftPadding?: boolean;
};

const screenSizeXS = 1080;
const screenSizeS = 1280;
const screenSizeM = 1536;
const screenSizeL = 2560;
const screenSizeHeaderImageBreakpoint = 1792;
const CALCULATE_SCALED_COVER_IMAGE_HEIGHT_PRESENTER_MODE =
	'min(max(calc(100vw * (7 / 38)), 140px), 750px)';
export const emojiVerticalAlignmentOffsetScreenSizeXS = -122;
export const emojiVerticalAlignmentOffsetScreenSizeSM = -104;
export const emojiVerticalAlignmentOffsetScreenSizeL = -95;
export const emojiVerticalAlignmentOffsetScreenSizeXL = -82;
const zoomLevelXSToM = 1.3;
const zoomLevelL = 2;

const cursorImage = `url(${cursorImg}), auto`;

const headerImageMediaQuery = `
  @media only screen and (min-width: ${screenSizeHeaderImageBreakpoint}px) {
    height: 360px;
  }
  @media only screen and (min-width: ${screenSizeM}px) and (max-width: ${screenSizeHeaderImageBreakpoint}px) {
    height: 240px;
  }
  @media only screen and (max-width: ${screenSizeM}px) {
    height: 240px;
  }
`;

export const mToLScreenZoomLevel = '1.5';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const PresenterModePortalContainer = styled.div<PresenterModePortalContainerProps>`
	width: 100vw;
	height: 100vh;
	background-color: ${token('elevation.surface', N0)};
	position: fixed;
	top: 0;
	left: 0;
	overflow-y: scroll;
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	cursor: ${({ isCustomCursorActive }) => (isCustomCursorActive ? cursorImage : 'auto')};
	.ak-renderer-tableHeader-sorting-icon {
		display: none;
	}
	.ak-renderer-tableHeader-sortable-column__button {
		cursor: ${({ isCustomCursorActive }) =>
			isCustomCursorActive ? `${cursorImage} !important` : 'auto !important'};
	}
	h1#title-text {
		text-align: center;
		padding-bottom: ${token('space.400', '32px')};
		justify-content: center;
	}
	span.inline-highlight {
		cursor: auto !important;
		background: none !important;
		border-bottom: none !important;
		box-shadow: none !important;
	}
	span.heading-anchor-wrapper {
		display: none;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const HeaderImageContainer = styled.div<HeaderImageContainerProps>`
	height: ${({ isCustomSitesPageTitleFFOn }) =>
		isCustomSitesPageTitleFFOn
			? `${CALCULATE_SCALED_COVER_IMAGE_HEIGHT_PRESENTER_MODE} !important`
			: 'unset'};
	width: 100vw;
	margin-bottom: ${token('space.1000', '80px')};
	background: ${token('color.background.neutral', N20)};
	${headerImageMediaQuery}
	.renderer-page-cover-picture {
		width: 100%;
		[class*='UnsplashWrapper'] {
			width: 100%;
		}
		[class*='LoadingDiv'] {
			max-width: unset;
			width: 100%;
			margin-top: ${token('space.0', '0px')};
			${headerImageMediaQuery}
		}
		img {
			margin: ${token('space.0', '0px')};
			max-width: unset;
			border-radius: unset;
			${headerImageMediaQuery}
		}
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const NoHeaderImageSpacer = styled.div`
	margin-bottom: ${token('space.400', '32px')};
	height: 150px;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const PresenterModeContainer = styled.div`
	width: 100%;
`;

export const scaleTo = (scale, isFirefox) => {
	return isFirefox ? `transform: scale(${scale})` : `zoom: ${scale};`;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const PresenterModeZoomContainer = styled.div<{ isFirefox: boolean }>`
	margin: 0 auto;
	transform-origin: top;
	@media only screen and (min-width: ${screenSizeL}px) {
		width: 1520px;
		${(props) => scaleTo(zoomLevelL, props.isFirefox)}
	}
	@media only screen and (min-width: ${screenSizeM}px) and (max-width: ${screenSizeL}px) {
		width: 1140px;
		${(props) => scaleTo(mToLScreenZoomLevel, props.isFirefox)}
	}
	@media only screen and (min-width: ${screenSizeXS}px) and (max-width: ${screenSizeM}px) {
		${(props) => scaleTo(zoomLevelXSToM, props.isFirefox)}
	}
	@media only screen and (min-width: ${screenSizeS}px) and (max-width: ${screenSizeM}px) {
		width: 988px;
	}
	@media only screen and (min-width: ${screenSizeXS}px) and (max-width: ${screenSizeS}px) {
		width: 760px;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const Spacer = styled.div`
	height: 50px;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const LoadingContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const TitleContainer = styled.div<TitleContainerProps>`
	display: flex;
	justify-content: ${({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'center' : 'initial')};
	flex-direction: ${({ isTitleCenterAligned, hasCoverPicture, isCustomSitesPageTitleFFOn }) =>
		isCustomSitesPageTitleFFOn && (isTitleCenterAligned || hasCoverPicture) ? 'column' : 'initial'};
	align-items: ${({ isTitleCenterAligned }) => (isTitleCenterAligned ? 'center' : 'baseline')};
	gap: ${({ isTitleCenterAligned, hasCoverPicture, hasEmoji, isCustomSitesPageTitleFFOn }) =>
		isCustomSitesPageTitleFFOn && (hasCoverPicture || (isTitleCenterAligned && hasEmoji))
			? token('space.200', '16px')
			: 'initial'};
	box-sizing: border-box;
	min-height: 76px;
	width: 100%;
	max-width: 760px;
	margin: 0 auto ${token('space.400', '32px')} auto;
	margin-top: ${({ hasCoverPicture, hasEmoji, isCustomSitesPageTitleFFOn, width }) =>
		isCustomSitesPageTitleFFOn && hasCoverPicture && hasEmoji
			? `${getEmojiVerticalAlignmentOffset(width)}px`
			: 'initial'};
	word-break: break-word;
	word-wrap: break-word;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const Title = styled.h1`
	&#title-text {
		${h800};
		font-weight: 500;
		margin-top: ${token('space.0', '0px')};
	}
	font-weight: 500;
	margin: ${token('space.0', '0px')};
	color: ${token('color.text', '#333')};
	word-break: break-word;
	word-wrap: break-word;
	min-height: ${token('space.500', '40px')};
	padding-top: ${token('space.075', '6px')};
	display: flex;
	font-size: calc(64px / ${zoomLevelXSToM});
	@media (min-width: ${screenSizeM}) {
		font-size: calc(72px / ${mToLScreenZoomLevel});
	}
	@media (min-width: ${screenSizeL}px) {
		font-size: calc(84px / ${zoomLevelL});
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const TitleTextWrapper = styled.div<TitleTextWrapperProps>`
	padding-left: ${({ shouldAddLeftPaddingCustomSites, shouldAddLeftPadding }) =>
		shouldAddLeftPaddingCustomSites
			? token('space.150', '12px')
			: shouldAddLeftPadding
				? token('space.100', '8px')
				: 'unset'};
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const EmojiWrapper = styled.div<EmojiWrapperProps>`
	display: flex;
	justify-content: center;
	max-width: ${({ isCustomSitesPageTitleFFOn, hasCoverPicture }) =>
		isCustomSitesPageTitleFFOn
			? hasCoverPicture
				? `${EMOJI_SIZE_LARGE}px`
				: `${EMOJI_SIZE_MEDIUM}px`
			: '26px'};
	align-items: center;
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const FlagsContainer = styled.div`
	zoom: 0.7;
	div[role='alert'] {
		margin: ${token('space.250', '20px')} auto;
		width: 600px;
	}
`;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
export const ButtonContainer = styled.span`
	margin-left: ${token('space.100', '8px')};
	display: inline-block;
`;

export const getEmojiVerticalAlignmentOffset = (width): number => {
	if (!width || width < screenSizeXS) {
		return emojiVerticalAlignmentOffsetScreenSizeXS;
	} else if (width < screenSizeM) {
		return emojiVerticalAlignmentOffsetScreenSizeSM;
	} else if (width < screenSizeL) {
		return emojiVerticalAlignmentOffsetScreenSizeL;
	} else {
		return emojiVerticalAlignmentOffsetScreenSizeXL;
	}
};
