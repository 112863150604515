import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-apollo';
import { defineMessages, useIntl } from 'react-intl-next';
import fscreen from 'fscreen';

import Portal from '@atlaskit/portal';
import Spinner from '@atlaskit/spinner/spinner';

import type {
	ContentUnifiedQueryType,
	ContentUnifiedQueryVariablesType,
} from '@confluence/content-unified-query';
import { ContentUnifiedQuery, ContentBlogUnifiedQuery } from '@confluence/content-unified-query';
import { getPublishedEmojiId } from '@confluence/emoji-title/entry-points/transformer';
import { LazyEmojiComponentLoader } from '@confluence/emoji-title';
import { PageContentRenderer } from '@confluence/content-renderer';
import { LazyQRCodeGenerator } from '@confluence/qr-code-generator';
import { LegacyMacroRendererContext } from '@confluence/fabric-extension-legacy-macro-renderer';
import { Attribution, TransparentErrorBoundary } from '@confluence/error-boundary';
import { setUserAgent, isFirefox } from '@confluence/browser-helper';
import {
	CUSTOM_SITES_PAGE_TITLE_FF,
	EMOJI_SIZE_LARGE,
	EMOJI_SIZE_MEDIUM,
} from '@confluence/emoji-title/entry-points/constants';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { useContentType } from '@confluence/page-context';

import { useFullscreenMode } from './useFullscreenMode';
import { useLockBodyScroll } from './useLockBodyScroll';
import { useHideFlags } from './useHideFlags';
import { HeaderPresenterMode } from './HeaderPresenterMode';
import { HeaderImage } from './HeaderImage';
import { ErrorFlag } from './ErrorFlag';
import {
	FlagsContainer,
	PresenterModePortalContainer,
	PresenterModeContainer,
	PresenterModeZoomContainer,
	Spacer,
	LoadingContainer,
	SpinnerContainer,
	TitleContainer,
	Title,
	EmojiWrapper,
	NoHeaderImageSpacer,
	TitleTextWrapper,
} from './presenterModeStyles';
import { PresenterModeContentTitleQuery } from './PresenterModeContentTitleQuery.graphql';
import { PresenterModeCoverPictureQuery } from './PresenterModeCoverPictureQuery.graphql';
import { usePresenterModeContext } from './PresenterModeContext';
import type {
	PresenterModeContentTitleQuery as PresenterModeContentTitleQueryType,
	PresenterModeContentTitleQueryVariables,
} from './__types__/PresenterModeContentTitleQuery';
import type {
	PresenterModeCoverPictureQuery as PresenterModeCoverPictureQueryType,
	PresenterModeCoverPictureQueryVariables,
} from './__types__/PresenterModeCoverPictureQuery';
import { useIsTitleCenterAligned } from './utilities';

const i18n = defineMessages({
	genericErrorMessage: {
		id: 'presentation-mode.portal.generic.error.message',
		description: 'Error Message for generic errors',
		// TODO: replace straight quotes with curly quotes (see go/curlyquotes)
		// eslint-disable-next-line no-restricted-syntax
		defaultMessage: "Something's not right. Refresh and try again.",
	},
});

type PresenterModePortalProps = {
	contentId: string;
	spaceKey: string;
	setFlagsVisibility: (areFlagsVisible: boolean) => Promise<void>;
};

export const PresenterModeZoomContainerId = 'presenter-mode-zoom-container';
export const PresenterModeContainerId = 'presenter-mode-container';
export const PresenterModePortalContainerId = 'presenter-mode-portal-container';
export const PresenterModePageTitleId = 'presenter-mode-title-text';
export const PresenterModeContentContainerId = 'presenter-mode-content-container';
export const PresenterModeBottomSpacerId = 'presenter-mode-content-container-bottom-spacer';

setUserAgent();

const legacyMacroRendererContextValue = { shouldRefetchMacroQuery: true };

/**
 * This component encapsulates the actual presenter mode experience. It's responsible
 * for rendering the nav bar and the content itself, which it gets via GraphQL.
 * Everything is placed within a Portal component which is rendered on top of the page.
 */
export const PresenterModePortal = ({
	contentId,
	spaceKey,
	setFlagsVisibility,
}: PresenterModePortalProps) => {
	const isCustomSitesPageTitleFFOn: boolean = useBooleanFeatureFlag(CUSTOM_SITES_PAGE_TITLE_FF);
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const [isCustomCursorActive, setIsCustomCursorActive] = useState(true);
	const [contentRendered, setContentRendered] = useState(false);
	const { isBrowserFullscreen } = usePresenterModeContext();
	const intl = useIntl();

	const handleSwitchCursor = (newValue: boolean) => setIsCustomCursorActive(newValue);

	// Prevent scrolling of content underneath the portal
	useLockBodyScroll();

	// Handles entering/exiting fullscreen mode
	useFullscreenMode();

	useEffect(() => {
		if (isBrowserFullscreen) {
			fscreen.exitFullscreen();
		}
	}, [isBrowserFullscreen]);

	// Handles hiding flags while in Presenter Mode
	useHideFlags(setFlagsVisibility);

	const {
		data: titleData,
		loading: titleLoading,
		error: contentTitleError,
	} = useQuery<PresenterModeContentTitleQueryType, PresenterModeContentTitleQueryVariables>(
		PresenterModeContentTitleQuery,
		{
			fetchPolicy: 'cache-first',
			variables: {
				contentId,
			},
		},
	);
	const emojiTitle = getPublishedEmojiId(titleData);

	let chooseContentUnifiedQuery = ContentUnifiedQuery;
	const [contentType] = useContentType();
	if (contentType === 'blogpost') {
		chooseContentUnifiedQuery = ContentBlogUnifiedQuery;
	}

	const {
		data: contentData,
		loading: contentLoading,
		error: contentUnifiedError,
	} = useQuery<ContentUnifiedQueryType, ContentUnifiedQueryVariablesType>(
		chooseContentUnifiedQuery,
		{
			fetchPolicy: 'cache-first',
			context: { single: true },
			skip: !contentId || !spaceKey,
			variables: {
				contentId,
				spaceKey,
				versionOverride: null,
				includeAlias: isSpaceAliasFFEnabled,
			},
		},
	);

	const {
		data: coverPictureQueryData,
		loading: coverPictureLoading,
		error: presenterModeCoverPictureError,
	} = useQuery<PresenterModeCoverPictureQueryType, PresenterModeCoverPictureQueryVariables>(
		PresenterModeCoverPictureQuery,
		{
			fetchPolicy: 'cache-first',
			context: { single: true },
			variables: {
				contentId,
				status: ['current'],
			},
		},
	);

	const loading = contentLoading || titleLoading || coverPictureLoading;

	const handleOnRenderedEvent = (): void => setContentRendered(true);

	const isTitleCenterAligned = useIsTitleCenterAligned(contentId, isCustomSitesPageTitleFFOn);

	const hasCoverPicture = !!coverPictureQueryData?.content?.nodes?.[0]?.properties?.nodes?.[0];

	const hasEmoji = !!emojiTitle;

	const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setViewportWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<Portal zIndex={519}>
			<TransparentErrorBoundary attribution={Attribution.COMMENTS}>
				<PresenterModePortalContainer
					id={PresenterModePortalContainerId}
					isCustomCursorActive={isCustomCursorActive}
				>
					<HeaderPresenterMode
						contentRendered={contentRendered}
						onSwitchCursorClick={handleSwitchCursor}
						contentId={contentId}
						spaceKey={spaceKey}
					/>
					<PresenterModeContainer id={PresenterModeContainerId}>
						{loading ? (
							<LoadingContainer data-testid="presenter-mode-spinner">
								<SpinnerContainer>
									<Spinner size="large" />
								</SpinnerContainer>
							</LoadingContainer>
						) : (
							<>
								{coverPictureQueryData ? (
									<HeaderImage
										contentId={contentId}
										coverPictureQueryData={coverPictureQueryData}
										isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
									/>
								) : (
									<NoHeaderImageSpacer />
								)}
								<PresenterModeZoomContainer
									data-testid="presenter-mode-zoom-container"
									id={PresenterModeZoomContainerId}
									isFirefox={isFirefox()}
								>
									<FlagsContainer>
										{(contentTitleError ||
											contentUnifiedError ||
											presenterModeCoverPictureError) && (
											<ErrorFlag title={intl.formatMessage(i18n.genericErrorMessage)} />
										)}
									</FlagsContainer>
									<TitleContainer
										id={PresenterModePageTitleId}
										data-testid="presenter-mode-title-container"
										isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
										hasCoverPicture={hasCoverPicture}
										isTitleCenterAligned={isTitleCenterAligned}
										hasEmoji={hasEmoji}
										width={viewportWidth}
									>
										{emojiTitle && (
											<EmojiWrapper
												isCustomSitesPageTitleFFOn={isCustomSitesPageTitleFFOn}
												hasCoverPicture={hasCoverPicture}
											>
												<span>
													<LazyEmojiComponentLoader
														emoji={emojiTitle}
														height={
															isCustomSitesPageTitleFFOn
																? hasCoverPicture
																	? EMOJI_SIZE_LARGE
																	: EMOJI_SIZE_MEDIUM
																: 26
														}
														hasCoverPicture={hasCoverPicture}
														isPageTitleComponent
														context="presenterMode"
														showTooltip
														renderResourcedEmoji={isCustomSitesPageTitleFFOn}
													/>
												</span>
											</EmojiWrapper>
										)}
										<TitleTextWrapper
											data-testId="titleTextWrapper"
											shouldAddLeftPaddingCustomSites={
												isCustomSitesPageTitleFFOn &&
												hasEmoji &&
												!isTitleCenterAligned &&
												!hasCoverPicture
											}
											shouldAddLeftPadding={!isCustomSitesPageTitleFFOn && hasEmoji}
										>
											<Title>{titleData?.content?.nodes?.[0]?.title ?? ''}</Title>
										</TitleTextWrapper>
									</TitleContainer>
									<LegacyMacroRendererContext.Provider value={legacyMacroRendererContextValue}>
										<PageContentRenderer
											contentId={contentId}
											data={contentData}
											onRendered={handleOnRenderedEvent}
											hasInlineComments={false}
											appearance="full-page"
											stickyHeaders={{
												offsetTop: 0,
											}}
											spaceKey={spaceKey}
										/>
									</LegacyMacroRendererContext.Provider>
									<Spacer id={PresenterModeBottomSpacerId} />
								</PresenterModeZoomContainer>
							</>
						)}
					</PresenterModeContainer>
				</PresenterModePortalContainer>
				<LazyQRCodeGenerator contentId={contentId} spaceKey={spaceKey} />
			</TransparentErrorBoundary>
		</Portal>
	);
};
